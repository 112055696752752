import React, { useState, useEffect, useRef } from 'react';
import { fetchOutlets, sendTruckAlert } from './api';
import './App.css';

const stateMapping = {
  MY_KL: 'KL & Selangor',
  MY_NSN: 'Negeri Sembilan',
  MY_MLK: 'Melaka',
  TH_BKK: 'Bangkok',
};

function App() {
  const [outlets, setOutlets] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [filteredOutlets, setFilteredOutlets] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState(null);

useEffect(() => {
  fetchOutlets()
    .then((data) => {
      // Filter out outlets with null pickup_address
      const validOutlets = data.filter((outlet) => outlet.pickup_address);

      // Remove duplicates based on outlet code
      const uniqueOutlets = Array.from(
        new Map(validOutlets.map((outlet) => [outlet.code, outlet])).values()
      );

      const processedOutlets = uniqueOutlets.map((outlet) => {
        let name = outlet.pickup_address.name;
        if (name.startsWith("Pop Meals @ ")) {
          name = name.replace("Pop Meals @ ", "");
        }
        return {
          ...outlet,
          name,
        };
      });

      // Sort outlets alphabetically
      processedOutlets.sort((a, b) => a.name.localeCompare(b.name));

      setOutlets(processedOutlets);

      const uniqueStates = Array.from(
        new Set(processedOutlets.map((outlet) => outlet.code.split('_').slice(0, 2).join('_')))
      );
      setStates(uniqueStates);
    })
    .catch((err) => alert(`Failed to fetch outlets: ${err.message}`));
}, []);

  const handleStateChange = (state) => {
    setSelectedState(state);
    setSelectedOutlet(null);
    const filtered = outlets.filter((outlet) => outlet.code.startsWith(state));
    setFilteredOutlets(filtered);
  };

  const handleTruckAction = (status) => {
    if (!selectedOutlet) return;
    sendTruckAlert(selectedOutlet.code, status)
      .then(() => alert(`Truck status for "${selectedOutlet.name}" updated successfully!`))
      .catch((err) => alert(`Failed to update truck status: ${err.message}`));
  };

  const getStateDisplayName = (stateCode) => stateMapping[stateCode] || stateCode;

  return (
    <div className="app-container">
      <h1>PopMeals Truck Driver Portal</h1>
      <header className="header">
        <label className="state-label">Select State:</label>
        <select
          className="dropdown"
          onChange={(e) => handleStateChange(e.target.value)}
          value={selectedState}
        >
          <option value="">--Select State--</option>
          {states.map((state) => (
            <option key={state} value={state}>
              {getStateDisplayName(state)}
            </option>
          ))}
        </select>
      </header>
      {!selectedOutlet ? (
        <div>
          <h2 className="sub-header">
            {selectedState
              ? `Outlets in ${getStateDisplayName(selectedState)}`
              : 'Select a state to view outlets'}
          </h2>
          <div className="outlet-grid">
            {filteredOutlets.length ? (
              filteredOutlets.map((outlet) => (
                <button
                  key={outlet.code}
                  className="button"
                  onClick={() => setSelectedOutlet(outlet)}
                >
                  {outlet.name}
                </button>
              ))
            ) : (
              <p className="no-outlets">No outlets available in the selected state.</p>
            )}
          </div>
        </div>
      ) : (
        <div>
          <h2 className="sub-header">
            {getStateDisplayName(selectedState)} - {selectedOutlet.name}
          </h2>
          <button
            className="button"
            onClick={() => handleTruckAction('ARRIVING_IN_10_MINUTES')}
          >
            Truck arriving in 10 minutes
          </button>
          <button
            className="button"
            onClick={() => handleTruckAction('ARRIVED')}
          >
            Truck has arrived!
          </button>
          <button className="back-button" onClick={() => setSelectedOutlet(null)}>
            Back to Outlets
          </button>
        </div>
      )}
    </div>
  );
}

export default App;
