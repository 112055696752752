const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Fetch outlets
export const fetchOutlets = async () => {
  const response = await fetch(`${BASE_URL}/v5/rider/hubs`);
  if (!response.ok) {
    throw new Error('Failed to fetch outlets');
  }
  return response.json();
};

// Send truck status alert
export const sendTruckAlert = async (hub_code, status) => {
  const response = await fetch(`${BASE_URL}/v1/admin/truck-alerts`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      City: 'Kuala_Lumpur',
    },
    body: JSON.stringify({ hub_code, status }),
  });
  if (!response.ok) {
    throw new Error('Failed to send truck alert');
  }
};
